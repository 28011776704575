body{
  margin: 0px;
}

@font-face {
  font-family: productSans_Regular;
  src: url('./assets/fonts/ProductSans-Regular.ttf');
}

@font-face {
  font-family: productSans_Medium;
  src: url('./assets/fonts/ProductSans-Medium.ttf');
}

@font-face {
  font-family: century_regular;
  src: url('./assets/fonts/Century.ttf');
}

/* @font-face {
  font-family: NunitoSans-SemiBold;
  src: url('./assets/fonts/NunitoSans-SemiBold.ttf');
} */

@font-face {
  font-family: Choplin-Black;
  src: url('./assets/fonts/Choplin/Choplin-Black.ttf');
}

@font-face {
  font-family: KalekoHeavy;
  src: url('./assets/fonts/zoom/KalekoHeavy.ttf');
}

@font-face {
  font-family: NunitoSans-Regular;
  src: url('./assets/fonts/nutrino/NunitoSans-Regular.ttf');
}

@font-face {
  font-family: NunitoSans-SemiBold;
  src: url('./assets/fonts/nutrino/NunitoSans-SemiBold.ttf');
}

@font-face {
  font-family: NunitoSans-Bold;
  src: url('./assets/fonts/nutrino/NunitoSans-Bold.ttf');
}

@font-face {
  font-family: NunitoSans-Black;
  src: url('./assets/fonts/nutrino/NunitoSans-Black.ttf');
}

.nav{
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
  background-color: #fff;
  overflow-x: auto;
  display: flex;
}
.nav li{
  list-style: none;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
  display: flex;
}
.nav-link{
  display: inline-block;
  color:#333;
  position: relative;
}
li.active{
  border-top: solid 3px #30d158;
  width: 100%;
  border-radius: 0px;
  transition: 0.5 ease;
} 
.headerMobile{
  position: fixed;
  top: 0px;
  width: 100%;
  height: 56px;
}
.searchBar{
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom:solid 1px #aaa;
}
.searchNavBar{
  padding-left: 15px;
  padding-right: 15px;
  height: 56px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%);
}
.headerTabBar{
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom:solid 1px #aaa;
}
.headerNavTabBar{
  display: flex;
  align-items: center;
  padding: 15px 10px;
  overflow-x: auto;
  margin-top: 5;
}
.headerbar{
  width: 100%;
  height: 60px;
  background-color: #333;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
.headerDrawerBar{
  height: 60px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: solid 1px #ececec;
}
.containProduct{
  position: absolute;
  top: 300px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  overflow: auto;
  padding-bottom: 100px;
  box-sizing: border-box;
}
.containCart{
  position: absolute;
  top: 70px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  overflow: auto;
  padding-bottom: 150px;
}
.loading{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
._button_action{
  position: absolute;
  top: 10px;
  width: 35px;
  height: 35px;
  background: #fff;
  border: solid 1px #aaa;
  border-radius: 50%;
  text-align: center;
  padding-top: 6px;
}

.pac-container {
  z-index: 99999999999 !important;
}

._edit{
  right: 5px;
}

._delete{
  right: 55px;
}

._button_action._edit:hover{
  background:#30d158;
  color:#fff;
  border:solid 1px #30d158;
}

._button_action._delete:hover{
  background:red;
  color:#fff;
  border:solid 1px red;
}

.tabs {
display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
width:100%;
margin-top:-7px;
}

.tabs label {
-webkit-box-ordinal-group: 1;
-moz-box-ordinal-group: 1;
-ms-flex-order: 1;
-webkit-order: 1;
order: 1;
-webkit-box-flex: 1;
-ms-flex-positive: 1;
flex-grow: 1;
padding: 12px 0;
cursor: pointer;
border-bottom: 1px solid #ececec;
text-align: center;
font-family: Arial;
font-size: 14px;
color: #bbb;
-moz-transition: all 0.5s ease;
-webkit-transition: all 0.5s ease;
-o-transition: all 0.5s ease;
-ms-transition: all 0.5s ease;
transition: all 0.5s ease;
}
.tabs__content {
-webkit-box-ordinal-group: 99;
-moz-box-ordinal-group: 99;
-ms-flex-order: 99;
-webkit-order: 99;
order: 99;
-webkit-box-flex: 1;
-ms-flex-positive: 1;
flex-grow: 1;
width: 100%;
display: none;
padding:20px 0;

}
.tabs input[type="radio"] {
display: none;
}
.tabs input[type="radio"]:checked + label {
border-bottom: 2px #000000 solid;
color: #000000;
-moz-transition: all 0.5s ease;
-webkit-transition: all 0.5s ease;
-o-transition: all 0.5s ease;
-ms-transition: all 0.5s ease;
transition: all 0.5s ease;
}
.tabs input[type="radio"]:checked + label + .tabs__content {
display: block;
}
.help-block{
    color:red;
    font-size:11px;
    font-family:"NunitoSans-Bold";
    margin-left: 17px;
}

.map-container {
  height: 100%; 
  width:100%;
}

.map-container-contact{
  height: 450px; 
  width:100%;
}

.search_input::placeholder{
  color: #aaa;
}

.item-list-search:hover{
  background-color: #f0f0f0;
}